body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  background-color: black;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1.25;
  margin: 0;
}

#app {
  padding: 0;
}

@keyframes dissolve {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes move {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(
      calc(var(--x, 0) * 100px),
      calc(var(--y, 0) * 100px)
    ) rotate(720deg);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes sparkle {
  0%, 100% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes crazySparkle {
  0%, 100% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  10%, 90% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    transform: scale(1) rotate(360deg);
  }
  60% {
    transform: scale(1.2) rotate(540deg);
  }
}

@keyframes colorShift {
  0%, 100% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(360deg);
  }
}

@keyframes dance {
  0% { transform: translateY(0); }
  100% { transform: translateY(-5px); }
}

@keyframes pulse {
  0% { transform: scale(1); }
  100% { transform: scale(1.05); }
}